import React from "react"
import Layout from "../components/layout"

const Contact = () => {
  return (
    <Layout>
      <h1>Contact me</h1>
      <p>Get in touch: me {"<at>"} schubam.com </p>
    </Layout>
  )
}

export default Contact
